<template>
  <TheNews :data="newsData" />
  <template v-for="section in sections" :key="section.id">
    <Section
      v-if="section.children"
      :background="section.background"
      :space="section.space"
    >
      <TheComponents v-if="section.children" :components="section.children" />
    </Section>
  </template>
</template>

<script setup>
import { Section } from 'refresh-ui';
import TheComponents from '@/components/TheComponents.vue';
import TheNews from '@/components/elements/TheNews.vue';
import { useRuntimeConfig } from '#app';
import { useI18n } from '#imports';
import { useData } from 'refresh-common';
import { useDataNews } from 'refresh-common';
const { t } = useI18n();

const config = useRuntimeConfig();

const props = defineProps({
  resource: Object,
});
const sections = useData(
  props.resource,
  {
    cmsBasePath: config.public.cmsBasePath,
    cloudname: config.public.cloudname,
  },
  t,
);
const newsData = useDataNews(props.resource, {
  cloudname: config.public.cloudname,
  cmsBasePath: config.public.cmsBasePath,
});
</script>
