<template>
  <div class="mr-4">
    <ShareNetwork
      key="facebook"
      network="facebook"
      :url="config.public.siteUrl + route.fullPath"
      :title="data?.title"
      :description="data?.preamble"
    >
      <Facebook class="h-[25px] text-blue" />
    </ShareNetwork>
  </div>
  <div class="mr-4">
    <ShareNetwork
      key="twitter"
      network="twitter"
      :url="config.public.siteUrl + route.fullPath"
      :title="data?.title"
      :description="data?.preamble"
    >
      <Twitter class="h-[25px] text-blue" />
    </ShareNetwork>
  </div>

  <div>
    <ShareNetwork
      key="linkedin"
      network="linkedin"
      :url="config.public.siteUrl + route.fullPath"
      :title="data?.title"
      :description="data?.preamble"
    >
      <Linkedin class="h-[25px] text-blue" />
    </ShareNetwork>
  </div>
</template>

<script setup>
import { useRoute, useRuntimeConfig } from '#app';
import Facebook from '@/components/atoms/Icons/Facebook.vue';
import Twitter from '@/components/atoms/Icons/Twitter.vue';
import Linkedin from '@/components/atoms/Icons/Linkedin.vue';

const route = useRoute();
const config = useRuntimeConfig();

defineProps({
  data: Object,
});
</script>
