<script setup>
import { computed } from 'vue';
import { buildUrl } from 'cloudinary-build-url';
import {
  useRoute,
  useRuntimeConfig,
  useLazyAsyncData,
  useHead,
  useServerSeoMeta,
} from '#imports';

import { Spotify } from 'refresh-ui';
import { useGetResource } from '@/composables/useJsonApi';
import NodeBlog from '@/components/NodeBlog.vue';
import NodePage from '@/components/NodePage.vue';
import PageLoader from '@/components/atoms/PageLoader.vue';

const route = useRoute();

const config = useRuntimeConfig();

const defaultImage =
  'https://res.cloudinary.com/klandestino-ab/image/upload/v1676532605/djurensratt-se/loggor/share_1200x630.png';

// TODO: Don't lazy load the main content -- it should very likely be loaded immediately
const {
  data: drupalRes,
  // eslint-disable-next-line no-unused-vars
  redirect,
  pending,
} = await useLazyAsyncData('drupalRes', () =>
  useGetResource({
    query: route.query,
    params: route.params,
    hash: route.hash,
    path: route.path,
  }),
);

// TODO: Improve this external redirect solution. There must be a more efficient way
if (drupalRes?.value?.redirect) {
  console.log('got it', drupalRes.value.redirect);
  // eslint-disable-next-line no-undef
  await navigateTo(drupalRes.value.redirect, { external: true });
}

// Set up meta tags
useHead({
  htmlAttrs: {
    lang: config.public.siteLang,
  },
});

const normalizeUrlEncoding = url => {
  if (!url) return '';
  if (url.includes('%25')) return decodeURIComponent(url); // Decode url because it has been url-encoded twice, based on a percentage sign encoded as %25
  return url;
};

const titleValue = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element.attributes.name === 'title',
    )?.attributes?.content || '',
);

const descriptionValue = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element.attributes.name === 'description',
    )?.attributes?.content || '',
);

const rawImage = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element.attributes.rel === 'image_src',
    )?.attributes?.href || defaultImage,
);

const shareImage = computed(() => {
  let img = rawImage?.value
    ? buildUrl(rawImage.value, {
        cloud: {
          cloudName: config.public.cloudname,
        },
        transformations: {
          resize: {
            type: 'fill',
            width: 1200,
            height: 630,
          },
        },
      })
    : '';
  return normalizeUrlEncoding(img);
});

// TODO: Fix this hack. We are getting the jsonapi url from the canonical url
const canonicalValue = computed(
  () =>
    /* drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element.attributes.rel === 'canonical',
    )?.attributes?.href || '',*/
    config.public.siteUrl + route.fullPath,
);

// console.log('canonical', canonicalValue.value);

const abstract = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.name === 'abstract',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
);

const ogTitle = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.property === 'og:title',
    )?.attributes?.content ||
    titleValue.value ||
    '',
);

const ogDescription = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.property === 'og:description',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
);

const ogType = computed(() => (drupalRes?.type === 'blog' ? 'article' : 'website'));

const twitterTitle = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.name === 'twitter:title',
    )?.attributes?.content ||
    titleValue.value ||
    '',
);

const twitterDescription = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.name === 'twitter:description',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
);

const twitterUrl = computed(
  () =>
    /* drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.name === 'twitter:url',
    )?.attributes?.content ||
    canonicalValue.value ||
    '', */
    canonicalValue.value,
);

const twitterSite = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.name === 'twitter:site',
    )?.attributes?.content || '',
);

const robots = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag_normalized?.find(
      element => element?.attributes?.name === 'robots',
    )?.attributes?.content || 'index, follow',
);

useServerSeoMeta({
  title: () => titleValue.value + ' | ' + config.public.siteName,
  description: () => descriptionValue.value,
  canonical: () => canonicalValue.value,
  lang: () => config.public.siteLang,
  ogImage: () => shareImage.value,
  ogUrl: () => canonicalValue.value,
  twitterCard: 'summary_large_image',
  twitterImage: () => shareImage.value,
  abstract: () => abstract.value,
  ogTitle: () => ogTitle.value,
  ogDescription: () => ogDescription.value,
  ogType: () => ogType.value,
  twitterTitle: twitterTitle.value,
  twitterDescription: () => twitterDescription.value,
  twitterUrl: () => twitterUrl.value,
  twitterSite: () => twitterSite.value,
  robots: () => robots.value,
});

/* console.log('meta', {
  title: titleValue.value,
  description: descriptionValue.value,
  canonical: canonicalValue.value,
  lang: config.public.siteLang,
  ogImage: shareImage.value,
  ogUrl: canonicalValue.value,
  twitterCard: 'summary_large_image',
  twitterImage: shareImage.value,
  abstract: abstract.value,
  ogTitle: ogTitle.value,
  ogDescription: ogDescription.value,
  ogType: ogType.value,
  twitterTitle: twitterTitle.value,
  twitterDescription: twitterDescription.value,
  twitterUrl: twitterUrl.value,
  twitterSite: twitterSite.value,
  robots: robots.value,
});*/
</script>

<template>
  <PageLoader :is-active="pending" />
  <Spotify />
  <template v-if="!pending">
    <NodeBlog v-if="drupalRes?.type === 'blog'" :resource="drupalRes?.data" />
    <NodePage v-else :resource="drupalRes?.data" />
  </template>
</template>
