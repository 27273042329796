<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="data.imageType === 'extra-wide'" class="mb-12">
    <Image
      v-if="data?.image?.url"
      :url="data?.image?.url"
      :alt="data.image.alt"
      class="aspect-[376/250] xl:aspect-[1920/700] 2xl:aspect-[1920/700]"
    />
  </div>

  <Container v-else class="mb-12 !px-0 lg:px-4">
    <Image
      v-if="data?.image?.url"
      :url="data?.image?.url"
      :alt="data.image.alt"
      class="aspect-[376/250] lg:rounded-2xl xl:aspect-[1260/500] 2xl:aspect-[1326/550]"
    />
  </Container>
  <Container class="!px-0 lg:px-4">
    <div class="flex flex-col-reverse lg:flex-row">
      <div
        class="lg:mr-18 flex flex-col items-center bg-blue-lighter py-12 lg:w-[245px] lg:bg-white xl:mr-48"
      >
        <Image
          v-if="data?.author?.avatar?.url"
          :url="data?.author?.avatar?.url"
          :alt="data?.author?.title"
          class="aspect-square !w-[100px] rounded-full lg:w-[100px]"
        />
        <h3
          v-if="data?.author?.title"
          class="mt-5 font-sans text-base text-blue lg:mt-8 lg:text-xl"
        >
          {{ data?.author?.title }}
        </h3>
        <span
          v-if="data?.author?.position"
          class="mt-1 font-sans text-xs text-black lg:text-sm"
        >
          {{ data?.author?.position }}
        </span>
        <span
          v-if="data?.date"
          class="mt-4 font-sans text-xs lowercase text-black lg:text-sm"
        >
          {{ dayjs(data?.date).format('DD MMMM YYYY') }}
        </span>
        <div
          class="my-10 flex flex-wrap items-center justify-center lg:flex-col lg:space-y-4"
        >
          <BadgeCategory
            v-for="(tag, index) in data?.tags"
            :key="index"
            :class="[index === 0 && 'bg-blue-dark']"
            class="mx-2 mb-4 lg:mb-0"
            :path="tag.path"
          >
            {{ tag.title }}
          </BadgeCategory>
        </div>
        <LinkSecondary :title="$t('more-news')" :link="newsUrl" type="blue" />
        <div class="mt-10 flex items-center">
          <span class="mr-4 font-serif text-base uppercase text-black"
            >{{ $t('share') }}:</span
          >
          <ClientOnly fallback-tag="span">
            <ShareSocial :data="data" />
          </ClientOnly>
        </div>
      </div>
      <div class="mx-4 flex max-w-2xl flex-col">
        <SubTitle class="text-blue">{{ data?.subtitle }}</SubTitle>
        <Title>{{ data?.title }}</Title>
        <div
          v-if="data?.preamble"
          class="mt-4 font-sans text-lg font-bold text-blue lg:mt-6 lg:text-xl"
          v-html="data?.preamble"
        ></div>
        <div
          v-if="data?.body"
          class="prose my-12 font-sans text-base text-black lg:prose-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:text-lg [&_p]:mb-4"
          v-html="data?.body"
        ></div>
      </div>
    </div>
  </Container>
</template>

<script setup>
import { useRuntimeConfig } from '#app';
import { computed } from 'vue';
import dayjs from 'dayjs';
import Image from '@/components/atoms/Image.vue';
import Container from '@/components/atoms/Container.vue';
import BadgeCategory from '@/components/atoms/BadgeCategory.vue';
import LinkSecondary from '@/components/atoms/LinkSecondary.vue';
import SubTitle from '@/components/atoms/SubTitle.vue';
import Title from '@/components/atoms/Title.vue';
import ShareSocial from '@/components/molecules/ShareSocial.vue';
import 'dayjs/locale/sv';
import { useI18n } from '#imports';

const { locale } = useI18n();

// dayjs locale
dayjs.locale(locale.value);

const config = useRuntimeConfig();

const newsUrl = computed(() => {
  switch (config.public.siteLang) {
    case 'sv':
      return config.public.newsSv;
    case 'en':
      return config.public.newsEn;
    default:
      return config.public.newsSv;
  }
});

defineProps({
  data: Object,
});
</script>
