import { buildUrl } from 'cloudinary-build-url';
import { STORAGE_TYPES } from '@cld-apis/utils';

export const useDataNews = (data, config: { cloudname: string, cmsBasePath: string }) => {

  const dataNews = data?.data;

  const getRelationships = (id, included) => {
    if (!!included && included.length > 0) {
      return included?.find(component => component.id === id);
    }
    return data.included?.find(component => component.id === id);
  };

  const getEntities = entities => {
    if (!entities) {
      return [];
    }

    return entities.map(entity => getRelationships(entity.id));
  };

  // TODO: Type properly
  const getImageOriginal = ({ data, included, baseUrl }: { data: unknown, included: unknown, baseUrl: string }) => {
    if (!data) return;

    const copy = JSON.parse(JSON.stringify(data));

    const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

    if (copy.length === 0) return;
    const image = getRelationships(id, included);

    if (image?.attributes?.computed_original_image) {
      return {
        url: baseUrl
          ? baseUrl + image?.attributes?.computed_original_image
          : image?.attributes?.computed_original_image,
      };
    }
    return undefined;
  };

  const proxyOriginalImage = ({ url, cloudinaryOptions, config }: { url: { url: string }, cloudinaryOptions: object, config: { cloudname: string, cmsBasePath: string }} ) => {
    const proxyUrl = buildUrl(url.url, {
      cloud: {
        cloudName: config.cloudname,
        storageType: STORAGE_TYPES.FETCH,
      },
      ...cloudinaryOptions
    });
    return { url: proxyUrl }
  };

  const getImageCloudinary = (data, options, included, config: { cloudname: string }) => {
    if (!data) return;

    const copy = JSON.parse(JSON.stringify(data));

    const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

    if (copy.length === 0) return;

    const image = getRelationships(id, included);

    const url = buildUrl(image?.attributes?.computed_cloudinary, {
      cloud: {
        cloudName: config.cloudname,
      },
      ...options,
    });

    return {
      type: 'image',
      url,
      alt: image?.attributes?.name,
    };
  };

  const getAuthor = (data, config: { cloudname: string, cmsBasePath: string }) => {
    if (!data) return;

    const copy = JSON.parse(JSON.stringify(data));
    const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;
    if (copy.length === 0) return;

    const author = getRelationships(id, {});
    return {
      title: author?.attributes?.title,
      position: author?.attributes?.field_position,
      avatar: author?.relationships?.field_node_media?.data
        ? getImageCloudinary(author?.relationships?.field_node_media?.data, {
            transformations: {
              format: 'webp',
              resize: {
                type: 'fill',
                width: 400,
              },
            },
          }, {}, { cloudname: config.cloudname })
          : proxyOriginalImage({
            url: getImageOriginal({
              data: author?.relationships?.field_image?.data, 
              included: {},
              baseUrl: config.cmsBasePath,
            }),
            cloudinaryOptions: {
              transformations: {
                format: 'webp',
                resize: {
                  type: 'fill',
                  width: 400,
                },
              },
            },
            config: { cloudname: config.cloudname, cmsBasePath: config.cmsBasePath },
          })
        /* getImageOriginal({
            data: author?.relationships?.field_image?.data, 
            included: {},
            baseUrl: config.cmsBasePath,
          }), */
    };
  };

  try {
    const tags =
      (dataNews?.relationships?.field_taxonomy_term_cat?.data &&
        dataNews?.relationships?.field_taxonomy_term_cat?.data.length > 0) ||
      (dataNews?.relationships?.field_taxonomy_term?.data &&
        dataNews?.relationships?.field_taxonomy_term?.data.length > 0)
        ? [
            ...getEntities(dataNews?.relationships?.field_taxonomy_term_cat?.data).map(
              tag => ({
                title: tag?.attributes?.title,
                path:
                  tag?.attributes?.path?.alias ||
                  '/node/' + tag?.attributes?.drupal_internal__nid,
              }),
            ),
            ...getEntities(dataNews?.relationships?.field_taxonomy_term?.data).map(
              tag => ({
                title: tag?.attributes?.title,
                path:
                  tag?.attributes?.path?.alias ||
                  '/node/' + tag?.attributes?.drupal_internal__nid,
              }),
            ),
          ]
        : getEntities(dataNews?.relationships?.field_tags?.data).map(tag => ({
            title: tag?.attributes?.name,
            path: tag?.attributes?.path?.alias,
          }));

    return {
      id: dataNews?.id,
      imageType: dataNews?.attributes?.field_article_image_type,
      image: dataNews?.relationships?.field_node_media?.data
        ? getImageCloudinary(dataNews?.relationships?.field_node_media?.data, {
            transformations: {
              format: 'webp',
              resize: {
                type: 'fill',
                width: 1920,
                height: 750,
              },
            },
          }, {}, { cloudname: config.cloudname })
        : proxyOriginalImage({
          url: getImageOriginal({
          // TODO: Here we probably need to add a fetch cloudinary image transform to optimize the legacy image
            data: dataNews?.relationships?.field_image?.data,
            included: {},
            baseUrl: config.cmsBasePath,
          }),
          cloudinaryOptions: {
            transformations: {
              format: 'webp',
              resize: {
                type: 'fill',
                width: 1920,
                height: 750,
              },
            },
          },
          config: { cloudname: config.cloudname, cmsBasePath: config.cmsBasePath },
        }),

      title: dataNews?.attributes?.title || '',
      subtitle: dataNews?.attributes?.field_subtitle || '',
      preamble: dataNews?.attributes?.field_wysiwyg?.processed || '',
      body: dataNews?.attributes?.body?.processed || '',
      date: dataNews?.attributes?.created || '',
      author: getAuthor(dataNews?.relationships?.field_author?.data, { cloudname: config.cloudname, cmsBasePath: config.cmsBasePath }),
      tags: tags,
    };
  } catch (error) {
    console.warn('NEWS DATA', error);
  }

  return null;
};
